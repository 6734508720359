// extracted by mini-css-extract-plugin
export var SliderWidth = "Trust-module--SliderWidth--030be";
export var arrowImg = "Trust-module--arrowImg--2aa3d";
export var card = "Trust-module--card--a5a7d";
export var cardImg = "Trust-module--cardImg--64312";
export var cardMian = "Trust-module--cardMian--83f69";
export var cardWrapper = "Trust-module--cardWrapper--d3bab";
export var cards = "Trust-module--cards--328e4";
export var cmsWebIconCOntainer = "Trust-module--cmsWebIconCOntainer--5ff6a";
export var cmsWebIconCOntainerLeft = "Trust-module--cmsWebIconCOntainerLeft--3fe5c";
export var description = "Trust-module--description--7b558";
export var heading = "Trust-module--heading--6837d";
export var headingPremium = "Trust-module--headingPremium--cd582";
export var mainWorkFeaturesWebCms = "Trust-module--mainWorkFeaturesWebCms--d6cad";
export var portfolioArrowIcon = "Trust-module--portfolioArrowIcon--cecd3";
export var portfolioArrowIconCover = "Trust-module--portfolioArrowIconCover--633fd";
export var portfolioArrowRightIconCover = "Trust-module--portfolioArrowRightIconCover--2b9d5";
export var premiumImg = "Trust-module--premiumImg--66163";
export var providingImg = "Trust-module--providingImg--971f3";
export var teamButton = "Trust-module--teamButton--d4415";
export var trailBg = "Trust-module--trailBg--ce8ac";