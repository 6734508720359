// extracted by mini-css-extract-plugin
export var bannerHeading = "Banner-module--bannerHeading--293c9";
export var bannerSubtitle = "Banner-module--bannerSubtitle--bb172";
export var bannerSubtitle2 = "Banner-module--bannerSubtitle2--78017";
export var cards = "Banner-module--cards--0b148";
export var form = "Banner-module--form--ca168";
export var heading = "Banner-module--heading--00c1c";
export var hireAndroidTopBanner = "Banner-module--hireAndroidTopBanner--fdc66";
export var talent = "Banner-module--talent--77ebc";
export var text = "Banner-module--text--4f1af";
export var tick = "Banner-module--tick--4687b";