import React from "react"
import { Link } from "gatsby"
import * as styles from "./FreeConsultation.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import freeRightimg from "../../images/hire-android/free-consultation-android-app.png"
const FreeConsultation = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.FreeConsultation}>
        <Container className={styles.innerFreeConsultation}>
          <Row>
            <Col lg={8} md={12} sm={12} xs={12}>
              <div className={styles.leftColFree}>
                <h2
                  className={styles.bannerHeadingFree}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <p
                  className={styles.bannerSubtitleFree}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
                {strapiData?.buttons[0]?.title && (
                  <div className={styles.btnYellowDiv}>
                    <Link
                      to={strapiData?.buttons[0]?.url}
                      className={styles.freeBtnYellow}
                    >
                      {strapiData?.buttons[0]?.title}
                    </Link>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={4} md={12} xs={12} sm={12}>
              <div className={styles.freeRight}>
                <img
                  src={freeRightimg}
                  alt={strapiData?.title}
                  className={styles.freeRightimg}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FreeConsultation
