// extracted by mini-css-extract-plugin
export var AngularDevelopersWeb = "HireDevelopers-module--AngularDevelopersWeb--2a038";
export var allSkills = "HireDevelopers-module--allSkills--ba2e9";
export var bottomRating = "HireDevelopers-module--bottomRating--00e56";
export var btnShowMore = "HireDevelopers-module--btnShowMore--ae66a";
export var card = "HireDevelopers-module--card--85ca5";
export var cardDesc = "HireDevelopers-module--cardDesc--a45c9";
export var cardWrapper = "HireDevelopers-module--cardWrapper--93ae0";
export var description = "HireDevelopers-module--description--40160";
export var devContent = "HireDevelopers-module--devContent--d1651";
export var developerImg = "HireDevelopers-module--developerImg--a9e71";
export var developersData = "HireDevelopers-module--developersData--713fe";
export var experience = "HireDevelopers-module--experience--6f600";
export var expert = "HireDevelopers-module--expert--aff28";
export var heading = "HireDevelopers-module--heading--778a3";
export var mernIcon = "HireDevelopers-module--mernIcon--aa935";
export var review = "HireDevelopers-module--review--4e8b6";
export var skill = "HireDevelopers-module--skill--3a2d4";
export var skills = "HireDevelopers-module--skills--8adaf";
export var typeBtn = "HireDevelopers-module--typeBtn--105b6";
export var typedevBtn = "HireDevelopers-module--typedevBtn--995a1";
export var whitePlus = "HireDevelopers-module--whitePlus--98159";