// extracted by mini-css-extract-plugin
export var TechStacksWebHireAndroid = "AngularTechStacks-module--TechStacksWebHireAndroid--f7cb1";
export var cardBg = "AngularTechStacks-module--cardBg--d04f4";
export var cards = "AngularTechStacks-module--cards--9bf10";
export var cardsContentAngular = "AngularTechStacks-module--cardsContentAngular--188c3";
export var description = "AngularTechStacks-module--description--f91ed";
export var heading = "AngularTechStacks-module--heading--cca8e";
export var iconssCard = "AngularTechStacks-module--iconssCard--d803b";
export var nav = "AngularTechStacks-module--nav--c9a57";
export var navItem = "AngularTechStacks-module--nav-item--b56d9";
export var navbarBlock = "AngularTechStacks-module--navbarBlock--0a299";
export var tabData = "AngularTechStacks-module--tabData--811ab";
export var tech = "AngularTechStacks-module--tech--f3132";
export var techIcon = "AngularTechStacks-module--techIcon--a02ab";
export var techImg = "AngularTechStacks-module--techImg--bff53";
export var technologyIcon = "AngularTechStacks-module--technologyIcon--bad84";