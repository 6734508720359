// extracted by mini-css-extract-plugin
export var bannerBtnHowAngular = "HowHireAngular-module--bannerBtnHowAngular--85724";
export var card = "HowHireAngular-module--card--c7643";
export var cardAllData = "HowHireAngular-module--cardAllData--655e7";
export var cardContent = "HowHireAngular-module--cardContent--4c7d8";
export var cardWrapper = "HowHireAngular-module--cardWrapper--701b8";
export var description = "HowHireAngular-module--description--18190";
export var getBtnAngular = "HowHireAngular-module--getBtnAngular--24928";
export var headerContent = "HowHireAngular-module--headerContent--1a85a";
export var heading = "HowHireAngular-module--heading--74acf";
export var howHireAngular = "HowHireAngular-module--howHireAngular--91f4f";
export var lottieImage = "HowHireAngular-module--lottieImage--1e2dd";
export var steps = "HowHireAngular-module--steps--c9d41";