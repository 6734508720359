// extracted by mini-css-extract-plugin
export var AngularIndusTechHireWeb = "AngularTechStacksMobile-module--AngularIndusTechHireWeb--d9538";
export var cardBg = "AngularTechStacksMobile-module--cardBg--8c389";
export var cards = "AngularTechStacksMobile-module--cards--c5e16";
export var cardsContent = "AngularTechStacksMobile-module--cardsContent--6d647";
export var description = "AngularTechStacksMobile-module--description--0305f";
export var heading = "AngularTechStacksMobile-module--heading--dcf87";
export var iconssCard = "AngularTechStacksMobile-module--iconssCard--4621d";
export var nav = "AngularTechStacksMobile-module--nav--074da";
export var navItem = "AngularTechStacksMobile-module--nav-item--463aa";
export var navbarBlock = "AngularTechStacksMobile-module--navbarBlock--34ad3";
export var tabData = "AngularTechStacksMobile-module--tabData--d9f51";
export var tech = "AngularTechStacksMobile-module--tech--127a8";
export var techIcon = "AngularTechStacksMobile-module--techIcon--eb67b";
export var techImg = "AngularTechStacksMobile-module--techImg--a095a";
export var technologyIcon = "AngularTechStacksMobile-module--technologyIcon--6f5d8";