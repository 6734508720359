// extracted by mini-css-extract-plugin
export var SliderWidth = "Projects-module--SliderWidth--5a5db";
export var arrowImg = "Projects-module--arrowImg--1dcdd";
export var bottomContent = "Projects-module--bottomContent--90d2d";
export var bottomHead = "Projects-module--bottomHead--96210";
export var bottomPort = "Projects-module--bottomPort--33800";
export var bottomPort2 = "Projects-module--bottomPort2--7aca9";
export var bottomTags = "Projects-module--bottomTags--be700";
export var bottomhead1 = "Projects-module--bottomhead1--411ad";
export var bottomtag1 = "Projects-module--bottomtag1--6e16f";
export var bottomtag2 = "Projects-module--bottomtag2--c832f";
export var bottomtag3 = "Projects-module--bottomtag3--cde65";
export var bottomtag3Hover = "Projects-module--bottomtag3Hover--7882b";
export var cardWrapper = "Projects-module--cardWrapper--674e9";
export var cards = "Projects-module--cards--35066";
export var description = "Projects-module--description--9a18c";
export var heading = "Projects-module--heading--c8312";
export var headingPremium = "Projects-module--headingPremium--1b59d";
export var mainWorkProjectsWebServices = "Projects-module--mainWorkProjectsWebServices--b70e4";
export var portfolioArrowIcon = "Projects-module--portfolioArrowIcon--034ea";
export var portfolioArrowIconCover = "Projects-module--portfolioArrowIconCover--2de6b";
export var portfolioArrowRightIconCover = "Projects-module--portfolioArrowRightIconCover--86e4e";
export var portfolioLinkImage = "Projects-module--portfolioLinkImage--ba651";
export var premiumImg = "Projects-module--premiumImg--6ee1e";
export var project2 = "Projects-module--project2--74eff";
export var projectsAll = "Projects-module--projectsAll--4f35b";
export var providingImg = "Projects-module--providingImg--24abb";
export var reactJsIconContainer = "Projects-module--reactJsIconContainer--194eb";
export var reactJsIconContainerLeft = "Projects-module--reactJsIconContainerLeft--28819";
export var teamButton = "Projects-module--teamButton--91d4c";
export var trailBg = "Projects-module--trailBg--d788b";