import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Tab from "react-bootstrap/Tab"
import "./AngularTechStacks.scss"
import * as styles from "./AngularTechStacks.module.scss"

const data = [
  {
    title: "Tools & Utilities",
    tech: [
      {
        name: "Android Studio IDE",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_studio_ide_8b0ed4ef07.svg",
      },
      {
        name: "Android Emulator",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_studio_ide_8b0ed4ef07.svg",
      },
      {
        name: "LeakCanary",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/leak_canary_fd4496c367.svg",
      },
      {
        name: "Vysor",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/vysor_737478a5a3.svg",
      },
      {
        name: "Zeplin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/zeplin_02b21011a1.svg",
      },
      {
        name: "Figma",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/figma_e5a541cf4e.svg",
      },
      {
        name: "Jira",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jira_01cd385612.svg",
      },
    ],
  },
  {
    title: "Languages & Frameworks",
    tech: [
      {
        name: "Java",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/java_54f81a1404.svg",
      },
      {
        name: "Kotlin",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_1ca18eb589.svg",
      },
      {
        name: "Android SDK",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_sdk_05673f6899.svg",
      },
      {
        name: "Jetpack Compose",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jetpack_compose_c59c94ce81.svg",
      },
      {
        name: "Android Jetpack",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_jetpack_569c3316fa.svg",
      },
    ],
  },
  {
    title: "Libraries, Components & Toolsets",
    tech: [
      {
        name: "RxJava",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/rx_java_239d3b8a5d.svg",
      },
      {
        name: "Kotlin Coroutines",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_coroutines_456de8c472.svg",
      },
      {
        name: "Kotlin Flow",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/kotlin_flow_1bd74a42fd.svg",
      },
      {
        name: "KOIN",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/koin_40e5740972.svg",
      },
      {
        name: "Retrofit",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/retrofit_a42b6fc397.svg",
      },
      {
        name: "Moshi",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/moshi_abcca42313.svg",
      },
      {
        name: "Glide",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/glide_358954cb29.svg",
      },
      {
        name: "WorkManager",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/work_manager_22f5f4117f.svg",
      },
      {
        name: "Jetpack Room",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jetpack_room_cab4825f79.svg",
      },
      {
        name: "Jetpack ViewModel",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jetpack_view_model_6d0f4fb750.svg",
      },
      {
        name: "Jetpack Navigation",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jetpack_navigation_0a02a409da.svg",
      },
      {
        name: "Jetpack Lifecycle",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/jetpack_lifecycle_8199fbc000.svg",
      },
      {
        name: "Material Components",
        icon: "https://invozone-backend.s3.amazonaws.com/material_ui_ac94034f00.svg",
      },
      {
        name: "View Components",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/view_components_8fd0a45950.svg",
      },
      {
        name: "Jetpack ViewModel",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/android_jetpack_569c3316fa.svg",
      },
    ],
  },
  {
    title: "QA tools & Build Tools",
    tech: [
      {
        name: "JUnit",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/j_unit_5_7fbc50403f.svg",
      },
      {
        name: "Mockito",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/mockito_1d52628785.svg",
      },
      {
        name: "Espresso",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/espresso_994e6dd45e.svg",
      },
      {
        name: "Gradle",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gradle_92a25546db.svg",
      },
      {
        name: "Jenkins",
        icon: "https://invozone-backend.s3.amazonaws.com/jenkins_4aa8b2f2e9.svg",
      },
      {
        name: "GitLab CI/CD",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/gitlab_ci_cd_4b72c9781b.svg",
      },
    ],
  },
  {
    title: "Databases & Storage",
    tech: [
      {
        name: "Firebase",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/firebase_db5cedd361.svg",
      },
      {
        name: "Room",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/room_165b16c12e.svg",
      },
      {
        name: "SQ Lite",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/sq_lite_bc711fcaf7.svg",
      },
      {
        name: "Shared Preference",
        icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/shared_preference_57a0ad8de0.svg",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = label => {
    setActiveTab(label)
  }

  return (
    <div className="WebTabStacksAndroidHire">
      <div className={styles.TechStacksWebHireAndroid}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          ></h2>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={`${data[0]?.title}`}
          >
            <div className={styles.cardsContentAngular}>
              <div>
                <Nav variant="pills" className={`WebTabStacksAndroidHire`}>
                  {data &&
                    data?.map((e, i) => (
                      <Nav.Item key={i} className="cards">
                        <div
                          eventKey={`${e?.title}`}
                          className={`tabLink ${
                            activeTab === e.title ? "active" : ""
                          }`}
                          onClick={() => handleTabClick(e.title)}
                        >
                          <div className={styles.tabData}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: e?.title,
                              }}
                            />
                          </div>
                        </div>
                      </Nav.Item>
                    ))}
                </Nav>
              </div>
              <Tab.Content className="contentBlockAngularWeb">
                {data &&
                  data?.map((e, i) => (
                    <div
                      key={i}
                      className={`tab-pane ${
                        activeTab === e.title ? "active" : ""
                      }`}
                    >
                      <div className={styles.techIcon}>
                        {e?.tech &&
                          e?.tech?.map((el, i) => (
                            <div className={`${styles.iconssCard}`} key={i}>
                              <div className={styles.technologyIcon}>
                                <div className={styles.techImg}>
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src={el?.icon}
                                    alt={el?.name}
                                  />
                                </div>
                                <h3>{el?.name}</h3>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
              </Tab.Content>
            </div>
          </Tab.Container>
        </Container>
      </div>
    </div>
  )
}
export default AngularTechStacks
