// extracted by mini-css-extract-plugin
export var bottomContent = "ProjectsMobile-module--bottomContent--14977";
export var bottomHead = "ProjectsMobile-module--bottomHead--ab90a";
export var bottomPort = "ProjectsMobile-module--bottomPort--bac62";
export var bottomPort2 = "ProjectsMobile-module--bottomPort2--449a3";
export var bottomTags = "ProjectsMobile-module--bottomTags--db658";
export var bottomhead1 = "ProjectsMobile-module--bottomhead1--b5402";
export var bottomtag1 = "ProjectsMobile-module--bottomtag1--f051c";
export var bottomtag2 = "ProjectsMobile-module--bottomtag2--fe063";
export var bottomtag3 = "ProjectsMobile-module--bottomtag3--2d67b";
export var bottomtag3Hover = "ProjectsMobile-module--bottomtag3Hover--1e82b";
export var cardWrapper = "ProjectsMobile-module--cardWrapper--4d88c";
export var caseStudiesBtnPortfolio = "ProjectsMobile-module--caseStudiesBtnPortfolio--250e8";
export var description = "ProjectsMobile-module--description--bfc25";
export var firstMobileImagaPort = "ProjectsMobile-module--firstMobileImagaPort--799ed";
export var firstPortBottom = "ProjectsMobile-module--firstPortBottom--3d69e";
export var firstPortFolio = "ProjectsMobile-module--firstPortFolio--61b35";
export var head = "ProjectsMobile-module--head--d0ad9";
export var heading = "ProjectsMobile-module--heading--fbb47";
export var hoverFirstPortImg = "ProjectsMobile-module--hoverFirstPortImg--e62e7";
export var normalrFirstPortImg = "ProjectsMobile-module--normalrFirstPortImg--00896";
export var portImage = "ProjectsMobile-module--portImage--43131";
export var portfolioLinkImage = "ProjectsMobile-module--portfolioLinkImage--73ba0";
export var project2 = "ProjectsMobile-module--project2--db07f";
export var projectsAll = "ProjectsMobile-module--projectsAll--419bf";
export var projectsMobileWeb = "ProjectsMobile-module--projectsMobileWeb--7ccd3";
export var tag1 = "ProjectsMobile-module--tag1--5ca95";
export var tag2 = "ProjectsMobile-module--tag2--7f85f";
export var tag3 = "ProjectsMobile-module--tag3--ab9c7";
export var tags = "ProjectsMobile-module--tags--9b3ad";