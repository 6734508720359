import React from "react"
import { Link } from "gatsby"
import * as styles from "./FreeConsultationMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
const FreeConsultationMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.FreeConsultationMobile}>
        <Container className={styles.innerFreeConsultation}>
          <Row>
            <Col md={12} sm={12} xs={12}>
              <div className={styles.leftColFree}>
                <h2
                  className={styles.bannerHeadingFree}
                  dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                />
                <p
                  className={styles.bannerSubtitleFree}
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.subTitle,
                  }}
                />
                {strapiData?.buttons[0]?.title && (
                  <div className={styles.btnYellowDiv}>
                    <Link
                      to={strapiData?.buttons[0]?.url}
                      className={styles.freeBtnYellow}
                    >
                      {strapiData?.buttons[0]?.title}
                    </Link>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FreeConsultationMobile
