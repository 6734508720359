import { Link } from "gatsby"
import React, { useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Button from "react-bootstrap/Button"
import ahmed from "../../images/hire-android/ahmd-android.png"
import zohaib from "../../images/hire-android/zohaib.png"
import zeeshan from "../../images/hire-mobile-app-developers/zeeshan-khan.webp"
import stars4 from "../../images/hire-typescript/4.5Stars.svg"
import fiveStars from "../../images/hire-typescript/5Stars.svg"
import * as styles from "./HireDevelopers.module.scss"
import plus from "../../images/hire-typescript/btnPlus.svg"
import mernIcon from "../../images/hireMernStack/armyIcon.svg"
const HireDevelopers = ({ strapiData }) => {
  const [showMoreSkills, setShowMoreSkills] = useState(false)
  const [showMoreSkills1, setShowMoreSkills1] = useState(false)
  const [showMoreSkills2, setShowMoreSkills2] = useState(false)
  const [showLessSkills, setShowLessSkills] = useState(false)
  const [showLessSkills1, setShowLessSkills1] = useState(false)
  const [showLessSkills2, setShowLessSkills2] = useState(false)

  const toggleShowMoreSkills = () => {
    setShowMoreSkills(!showMoreSkills)
    setShowLessSkills(false)
  }
  const toggleShowMoreSkills1 = () => {
    setShowMoreSkills1(!showMoreSkills1)
  }
  const toggleShowMoreSkills2 = () => {
    setShowMoreSkills2(!showMoreSkills2)
  }
  const toggleShowLessSkills = () => {
    setShowMoreSkills(false)
    setShowLessSkills(!showLessSkills)
  }
  const toggleShowLessSkills1 = () => {
    setShowMoreSkills1(false)
    setShowLessSkills1(!showLessSkills1)
  }
  const toggleShowLessSkills2 = () => {
    setShowMoreSkills2(false)
    setShowLessSkills2(!showLessSkills2)
  }
  const devSDetails = [
    {
      name: "Ahmad Wahaj",
      experience: "3 Years Of Experience",
      desc: "His development skills and innovative approach have helped our partners develop flawless & secure Android apps with multiple functionalities and smooth user interface.",
      rating: fiveStars,
      reviews: "<b>5.0</b> Reviews",
    },
    {
      name: "Zeeshan Khan",
      experience: "6+ Years Of Experience",
      desc: "Zeeshan’s development skills are great for creating apps for the Android platform. He is a pro in Model-View-ViewMode, Model-View-Controller, and Model-View-Presenter.",
      rating: stars4,
      reviews: "<b>4.9</b> Stars Reviews",
    },
    {
      name: "Zohaib Javed",
      experience: "2 Years of experience",
      desc: "With broad experience in web and mobile application development, Zohaib has actively contributed to projects incorporating Deep Learning and Computer Vision technologies.",
      rating: stars4,
      reviews: "<b>4.8</b> Stars Reviews",
    },
  ]
  const dataDev1 = [
    {
      skill: "Android",
    },
    {
      skill: "Java",
    },
    {
      skill: "Kotlin",
    },
    {
      skill: "Android SDK",
    },
    {
      skill: "Flow",
    },
    {
      skill: "Google Cloud Platform",
    },
    {
      skill: "MySQL",
    },
    {
      skill: "MongoDB",
    },
  ]
  const dataDev2 = [
    {
      skill: "Android",
    },
    {
      skill: "Android SDK",
    },
    {
      skill: " Java",
    },
    {
      skill: "Kotlin",
    },
    {
      skill: "Coroutines",
    },
    {
      skill: "Python",
    },
    {
      skill: "Swift",
    },
    {
      skill: "iOS",
    },
    {
      skill: "PHP",
    },
  ]
  const dataDev3 = [
    {
      skill: "Android",
    },
    {
      skill: "Kotlin",
    },
    {
      skill: " Java",
    },
    {
      skill: "Flow",
    },
    {
      skill: "Android SDK",
    },
    {
      skill: "View Model",
    },
  ]
  return (
    <React.Fragment>
      <div className={styles.AngularDevelopersWeb}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row className={styles.cardWrapper}>
            {devSDetails &&
              devSDetails.map((e, i) => (
                <Col lg={4} md={6} sm={12} xs={12} key={i}>
                  <div className={styles.card}>
                    <div className={` d-flex ${styles.developersData}`}>
                      <div className={styles.developerImg}>
                        <img
                          src={
                            i === 0
                              ? ahmed
                              : i === 1
                              ? zeeshan
                              : i === 2
                              ? zohaib
                              : ""
                          }
                          alt={e?.name}
                        />
                      </div>
                      <div className={styles.devContent}>
                        <h3>{e?.name}</h3>
                        <p className={styles.experience}>{e?.experience}</p>
                      </div>
                    </div>
                    <div className={styles.allSkills}>
                      {i === 0 && (
                        <React.Fragment>
                          {dataDev1 &&
                            dataDev1
                              .slice(0, showMoreSkills ? dataDev1.length : 5)
                              .map((s, eIdx) => (
                                <div className={styles.skills} key={eIdx}>
                                  <div
                                    className={`${styles.skill}`}
                                    data-index={eIdx}
                                  >
                                    {s?.skill}
                                  </div>
                                </div>
                              ))}
                          {showMoreSkills && (
                            <div>
                              <button
                                onClick={toggleShowLessSkills}
                                className={styles.btnShowMore}
                              >
                                See less
                              </button>
                            </div>
                          )}
                          {!showMoreSkills && (
                            <div>
                              <button
                                onClick={toggleShowMoreSkills}
                                className={styles.btnShowMore}
                              >
                                See more{" "}
                                <img
                                  className={styles.whitePlus}
                                  src={plus}
                                  alt="plus"
                                />
                              </button>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      {i === 1 && (
                        <React.Fragment>
                          {dataDev2 &&
                            dataDev2
                              .slice(0, showMoreSkills1 ? dataDev2.length : 5)
                              .map((sl, el) => (
                                <div className={styles.skills} key={el}>
                                  <div
                                    className={`${styles.skill}`}
                                    data-index={el}
                                  >
                                    {sl?.skill}
                                  </div>
                                </div>
                              ))}
                          {showMoreSkills1 && (
                            <div>
                              <button
                                onClick={toggleShowLessSkills1}
                                className={styles.btnShowMore}
                              >
                                See less
                              </button>
                            </div>
                          )}
                          {!showMoreSkills1 && (
                            <div>
                              <button
                                onClick={toggleShowMoreSkills1}
                                className={styles.btnShowMore}
                              >
                                See more{" "}
                                <img
                                  className={styles.whitePlus}
                                  src={plus}
                                  alt=""
                                />
                              </button>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                      {i === 2 && (
                        <React.Fragment>
                          {dataDev3 &&
                            dataDev3
                              .slice(0, showMoreSkills2 ? dataDev3.length : 7)
                              .map((al, ei) => (
                                <div className={styles.skills} key={ei}>
                                  <div
                                    className={`${styles.skill}`}
                                    data-index={ei}
                                  >
                                    {al?.skill}
                                  </div>
                                </div>
                              ))}
                        </React.Fragment>
                      )}
                    </div>
                    <p className={styles.cardDesc}>{e?.desc}</p>
                    <div className={styles.typedevBtn}>
                      <Link to="#hireandroidapp">
                        <Button className={styles.typeBtn}>
                          <img
                            src={mernIcon}
                            alt="menu icon"
                            className={styles.mernIcon}
                          />
                          {`Hire ${e?.name}`}
                        </Button>
                      </Link>
                    </div>
                    <div className={styles.bottomRating}>
                      <img src={e?.rating} alt="rating" />
                      <div
                        className={styles.review}
                        dangerouslySetInnerHTML={{
                          __html: e?.reviews,
                        }}
                      />
                    </div>
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default HireDevelopers
