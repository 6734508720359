import React from "react"
import * as styles from "./HowHireAngular.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { Link } from "gatsby"
const HowHireAngular = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.howHireAngular}>
        <Container>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <Row
            className={`gap-30 justify-content-center ${styles.cardWrapper}`}
          >
            {strapiData?.cards?.map((e, i) => (
              <Col xl={6} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.card}>
                  <div
                    className={`d-flex justify-content-between ${styles.cardAllData}`}
                  >
                    <div className={styles.cardContent}>
                      <div
                        className={` align-items-center d-flex ${styles.headerContent}`}
                      >
                        <h3>{e?.title}</h3>
                        <span className={styles.steps}>Step 0{i + 1}</span>
                      </div>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                    <div className={styles.lottieImage}>
                      <lottie-player
                        autoplay
                        loop
                        src={e?.image1[0]?.localFile?.publicURL}
                        style={{ height: "105px", width: "125px", margin: "0" }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className={`${styles.bannerBtnHowAngular}`}>
            <Link to="#hireandroidapp" className={styles.getBtnAngular}>
              {strapiData?.buttons[0]?.title}
            </Link>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default HowHireAngular
