// extracted by mini-css-extract-plugin
export var AngularIndustriesWeb = "AngularIndustries-module--AngularIndustriesWeb--3b5ed";
export var cardWrapper = "AngularIndustries-module--cardWrapper--ab781";
export var cardWrapper2 = "AngularIndustries-module--cardWrapper2--3786a";
export var cards = "AngularIndustries-module--cards--29951";
export var cards2 = "AngularIndustries-module--cards2--0dbe5";
export var description = "AngularIndustries-module--description--753ee";
export var firstMobileCard = "AngularIndustries-module--firstMobileCard--3a185";
export var heading = "AngularIndustries-module--heading--fa8bd";
export var mainHeading = "AngularIndustries-module--mainHeading--ab417";
export var webIndustries = "AngularIndustries-module--webIndustries--5269f";
export var webIndustriesHeading = "AngularIndustries-module--webIndustriesHeading--b0688";