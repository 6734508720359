import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
import * as styles from "./AngularIndustries.module.scss"

const AngularIndustries = ({ strapiData, webappIndustries }) => {
  return (
    <div
      className={`${styles.AngularIndustriesWeb} ${
        webappIndustries ? styles.webIndustries : ""
      } `}
    >
      <Container>
        <div className={`${styles.heading}`}>
          <h2
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            className={` ${styles.mainHeading} ${
              webappIndustries ? styles.webIndustriesHeading : ""
            }`}
          />
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
        </div>
        <Row className={` gap-30 justify-content-center ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col lg={3} md={6} sm={12} xs={12} key={i}>
              <Link to={e?.buttons[0]?.url}>
                <div className={styles.cards}>
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{ height: "60px", width: "60px", margin: "auto" }}
                  />
                  <Link to={e?.buttons[0]?.url}>
                    <h3>{e?.title}</h3>
                  </Link>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
        <Row
          className={` gap-30 justify-content-center ${styles.cardWrapper2}`}
        >
          {strapiData?.cards?.map((e, i) => (
            <Col lg={3} md={6} sm={12} xs={12}>
              <Link to={e?.buttons[0]?.url}>
                <div
                  className={i === 0 ? styles.firstMobileCard : styles.cards2}
                >
                  <div className={` d-flex align-items-center `}>
                    <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{ height: "60px", width: "60px", margin: "0px" }}
                    />
                    <p>{e?.title}</p>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default AngularIndustries
